.containerInfoBoxList {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;

  background: #ffffff;

  flex: none;
}

.textInfoBoxList {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #282e37;
  flex: none;
}

.rond {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  margin-right: 10px;
}

.blue {
  background-color: #2b80fe;
}

.green {
  background-color: #18ba59;
}

.yellow {
  background-color: #ebc501;
}

.red {
  background-color: #da335b;
}

.purple {
  background-color: #c400f3;
}

.lightBlue {
  background-color: #9ae1f2;
}

.action:hover,
.action:hover > .textInfoBoxList {
  background: white;
  border-color: #0c69f5;
  color: #0c69f5;
}
