.button {
  background-color: #0c69f5;
  border-radius: 8px;
  font: inherit;
  color: #ffffff;
  border: none;
  white-space: nowrap;
  cursor: pointer;
  padding: 0.5em 1em;
  vertical-align: top;
}

.button:hover {
  background-color: #0272a4;
}

.button:active {
  background-color: #025f86;
}

.button:disabled {
  background-color: #8c94a1;
  border-color: #8c94a1;
  cursor: default;
  pointer-events: none;
  color: #d3d3d3;
}

.inner {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
}

.delete {
  background-color: #f50c14;
  margin-left: auto;
}

.secondaryDelete:hover {
  background-color: rgba(245, 12, 20, 0.44);
}

.secondaryDelete {
  background-color: #ffffff;
  border: 1px solid #f50c14;
  color: #f50c14;
}

.secondary {
  background-color: white;
  border: 1px solid #0c69f5;
  color: #0c69f5;
}

.secondary:hover {
  background-color: #0c69f5;
  color: white;
}

.blue {
  border: 1px solid #1e3a67;
  background-color: #edf3fd;
  color: #1e3a67;
}

.blue:hover {
  background-color: white;
  border-color: #0c69f5;
  color: #0c69f5;
}

.secondaryYellow {
  background-color: #ffffff;
  border: 1px solid #f4bb0a;
  color: #f4bb0a;
}

.secondaryYellow:hover {
  background-color: rgba(244, 187, 10, 0.19);
}

.secondaryGreen {
  background-color: #ffffff;
  border: 1px solid green;
  color: green;
}

.secondaryGreen:hover {
  background-color: rgba(0, 128, 0, 0.24);
}

.link {
  color: #0c69f5;
  padding: 0;
  border-radius: 0;
}

.link:disabled {
  color: #8c94a1;
}

.link,
.link:hover,
.link:disabled {
  background-color: transparent;
}
