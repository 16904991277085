.row {
  display: grid;
  grid-gap: 10px;
  color: #282e37;
  height: 64px;
  align-content: center;
}

.overlay {
  grid-column: 1 / -1;
  display: grid;
  place-content: center;
}

.disabledNote {
  opacity: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.overlay:hover .disabledNote {
  opacity: 1;
}

.item {
  align-content: center;
  border-bottom: 1px solid #ced4de;
  padding-block: 1rem;
}

.item:not(:first-child) {
  padding-left: 1rem;
}

.rowLocataire {
  display: grid;
  position: relative;
  grid-template-columns: 2fr 2fr minmax(min-content, max-content) 1fr 0.3fr;
  align-content: center;
}

.rowCandidat {
  display: grid;
  grid-template-columns:
    1fr 1fr 1fr minmax(min-content, max-content) max-content 1fr minmax(
      min-content,
      max-content
    )
    0.3fr;
  align-content: center;
}

.font_blue {
  background-color: #f2f6ff;
}

.head {
  padding: 5px;
  font-weight: 500;
  font-size: 0.8rem;
  height: 3em;
  border-bottom: solid;
  align-content: center;
  border-bottom-color: #d0d5e1;
  border-bottom-width: 1px;
  color: #62738f;
}

.nom {
  font-weight: 700;
  font-size: 14px;
  align-content: center;
}

.mail {
  font-size: 13px;
  align-content: center;
  color: #62738f;
}

.bail {
  font-weight: 300;
  font-size: 10px;
  align-content: center;
  color: #5f6d82;
}

.standard {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #282e37;
}

.columnCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  align-items: center;
}

.columnSpace {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.smallImage {
  width: 5em;
  border-radius: 8px;
}

.preavis {
  color: #c78f00;
}

.rowSpan {
  grid-column: 1/-1;
  text-align: center;
  margin-top: 2em;
}

@media (max-width: 799px) {
  .relative {
    position: relative;
  }

  .dotsPosition {
    position: absolute;
    top: 0;
    right: 1em;
  }

  .statusPosition {
    position: absolute;
    top: 3em;
    right: 1em;
  }

  .item {
    border: none;
    padding-block: 0.5em;
  }

  .noPadding {
    padding-block: 0.1em;
  }
}
